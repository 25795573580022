.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  padding-top: 1vw;
  // height: 17.5%;

  &__btn {
    &-group {
      width: 50%;
      padding-top: 1vw;
    }

    height: 4vw;
    // max-height: 8vh;

    &:first-child {
      margin-right: 2vw;
    }
  }

  &__logo {
    height: 7.5vw;
    // max-height: 16vh;
  }

  &__user-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 2vw;
    width: 50%;
    padding-top: 0.5vw;

    > div {
      margin-left: 1vw;
    }
  }

  &__username {
    color: #f2e02f;
  }

  &__user-attribute {
    img {
      padding-top: 0.25vw;
      height: 2vw;
    //   max-height: 3.5vh;
    }
  }
}
