.gift-history {
  width: 100%;
  display: grid;
  grid-gap: 2vw;
  margin: 0 auto;
  grid-template-columns: repeat(5, 1fr);

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-img {
      width: 6vw;
    }

    &-name {
      margin-top: 1vh;
      text-align: center;
      font-size: 1.4vw;
    }
  }
}
