.gift-reveal {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	//   min-height: 54vh;
	flex-grow: 1;
	font-size: 3vw;

	&__title {
		font-size: 3vw;
		// margin-bottom: 2vh;
		text-transform: uppercase;
		text-align: center;
	}

	.custom-close {
		width: 17.5vh;
		// max-width: 25vh;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% 100%;
		height: 12.5vh;
	}

	&__list {
		width: 70%;
		// display: grid;
		// grid-template-columns: repeat(5, 18%);
		// grid-gap: 2.5%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// margin-bottom: 2vh;
		max-height: 100%;
		// overflow-y: auto;
		// overflow-x: hidden;

		&.single {
			display: flex;
			justify-content: center;
			align-items: center;
			//   overflow-y: hidden;
			.gift-reveal__item {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				width: 10vw;
				img {
					width: 100%;
					margin: 0 auto;
				}
				font-size: 2vw;
			}
		}
	}

	&__item {
		width: calc(100% / 5.5);
		font-size: 1.4vw;
		img {
			width: 100%;
		}
		&-name {
			text-align: center;
		}
	}
}
