.gift-list {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;

  &__tier {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
    &-name {
      margin-right: 1vw;
      font-size: 3vw;
      width: max-content;
    }

    &-accent {
      height: 2px;
      flex-basis: 1vw;
      flex-grow: 1;
      background: white;
    }
  }

  &__item {
    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2vh;

      .combination {
        display: grid;
        grid-gap: 2vw;
        grid-template-columns: repeat(3, 7vw);

        &-alt {
          width: 25vw;
        }
      }

      .divider {
        font-weight: bold;
        font-size: 7vw;
        line-height: 0.7;
        padding-bottom: 4%;
      }

      .result {
        img {
          width: 7vw;
        }
      }
    }
    &-name {
      font-size: 1.4vw;
      text-align: center;
      width: 100%;
    }

    &-img {
      width: 100%;
    }
  }
}
