.item {
	position: relative;
	width: 100%;

	&__bg {
		width: 100%;
		height: 95%;
	}

	&__body {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
	}

	&__img {
		width: 90%;
		height: 82%;
		object-position: center;
		object-fit: contain;
	}

	&__btn {
		position: absolute;
		bottom: 6.5%;
		width: 100%;
		height: 15%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.35vw;
		color: black;
		font-style: italic;
		text-transform: uppercase;
		white-space: nowrap;

		img {
			height: 1.4vw;
			margin-left: 0.25vw;
		}
	}

	&__name {
		position: absolute;
		left: 50%;
		bottom: 25%;
		transform: translateX(-50%);
		font-size: 2vmin;
		width: 80%;
		text-align: center;
		font-style: italic;
		color: black;
	}

	&-list {
		position: relative;
		margin-top: 6.5vmin;
		height: 60vh;

		&__bg {
			width: 80vmin;
			//   max-width: 100vh;
			height: 100%;
		}

		&__body {
			position: absolute;
			height: 85%;
			width: 90%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&__content {
			width: 100%;
			height: 100%;
			margin: 0 auto;
			overflow: auto;
			display: grid;
			padding: 0 1vw;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: max-content;
			grid-gap: 0.75vw;
		}
	}
}
