.spin-machine {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	margin-top: -2vmin;
	width: 49vmin;
	height: 83%;

	&__title {
		width: 100%;

		&-img {
			// height: 15vh;
			width: 100%;
			//   max-width: 60vh;
		}
	}

	.carousel {
		width: 100%;
		height: 100%;
		margin: 0 auto;
	}

	&__slider {
		// height: 4vw;
	}

	&__item {
		position: relative;
		height: 4vw;
		width: 4vw;
		margin: 0 auto;
		&-bg {
			height: 4vw;
		}
		&-img {
			//   position: absolute;
			//   top: 50%;
			//   left: 50%;
			//   transform: translate(-50%, -50%);
			height: 100%;
			width: 100%;
			max-width: 100%;
			//   object-fit: contain;
			//   object-position: center;
		}

		&:not(:first-child) {
			margin-left: 1vw;
		}

		&-list {
			width: 72%;
			height: 15%;
		}
	}

	&__bg {
		width: 100%;
		vertical-align: middle;

		&-wrapper {
			position: relative;
			width: 70%;
		}
	}

	&__btn-history {
		position: absolute;
		right: -32%;
		bottom: 9%;
		width: 26%;

		img {
			width: 100%;
			height: auto;
			max-width: 100%;
		}
	}

	&__btn {
		width: 100%;

		&-group {
			position: relative;
			display: flex;
			justify-content: space-evenly;
			width: 100%;
			height: 10%;
			color: black;

			button {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 40%;
				height: 100%;
				background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/dutyshop/btn.png") center/contain no-repeat;

				> span {
					font-size: 3vmin;
					font-family: "RefrigeratorDeluxe", sans-serif;
					line-height: 1;
					margin-bottom: -1%;

					&:last-child {
						font-size: 2vmin;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: -1%;
						margin-bottom: 0;
					}

					img {
						height: 2vmin;
						align-self: center;
						margin-left: 3%;
					}
				}
			}
		}
	}
}
