.confirmation-exchange {
	flex-grow: 1;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	text-align: center;
	&__text {
		font-size: 5vh;

		.coin {
			margin: 0 0.5vh;
		}
	}

	.item-img {
		width: 100%;
		max-width: 12vw;
		img {
			max-height: 100%;
			width: 100%;
			height: auto;
		}
	}
}

.confirmation-buy {
	display: flex;
	justify-content: space-evenly;
	align-items: stretch;
	flex-direction: column;
	// min-height: 32vh;
	flex-grow: 1;
	height: 100%;
	text-align: center;
	&__text {
		font-size: 3vw;
		text-transform: uppercase;

		.coin {
			height: 3vw;
			margin: 0 0.5vw;
			vertical-align: bottom;
		}
	}

	&__img {
		max-height: 17vh;
		height: 8vw;
	}

	&__toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2vw;
		color: #c6a115;
		cursor: pointer;
		text-transform: uppercase;
		> .checkbox {
			position: relative;
			margin-right: 1vw;

			.checkbox {
				&__bg {
					height: 2vw;
					max-height: 4vh;
				}

				&__mark {
					position: absolute;
					width: 90%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: all 0.3s;

					&.disable {
						transform: translate(-100%, -100%) scale(0) rotateZ(-45deg);
					}
				}
			}
		}
	}

	&__exchange-input {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		font-size: 4vw;
		.left,
		.right {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.left {
			.text {
				margin-right: 1vw;
			}
		}

		.right {
			.text {
				margin-left: 1vw;
			}
			.confirmation-buy__coin {
				margin: 0 1vw;
			}
		}

		img {
			max-height: 7vh;
			height: 4vw;
		}
	}
}

.btn-confirmation-spin {
	position: absolute;
	top: 42%;
	left: 50%;
	transform: translate(-50%, -42%);
	font-family: "RefrigeratorDeluxe", sans-serif;
	font-size: 3.45vh;
}
