.modal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1000;

	&.disable {
		z-index: -1;

		.modal__bg {
			background: rgba(black, 0);
		}

		.modal__body {
			transform: translate(-50%, 0);
			opacity: 0;
		}
	}

	&__bg {
		width: 100%;
		height: 100%;
		background: rgba(black, 0.4);
	}

	&__body {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 90%;
		max-height: 90%;
		opacity: 1;
		transition: all 0.3s;
	}

	&__content {
		&-bg {
			width: 60vw;
		}
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		padding: 2vh 2vw 4vh;

		&.closer-to-middle {
			height: 80%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.modal__conntent-main {
				height: 50%;
			}
		}

		&-main {
			&.scrollable {
				background: rgba(white, 0.15);
				color: white;
				overflow: auto;
				margin: 3vh 0 2vh 0;
				height: 50%;
				padding: 2vw;
			}
			flex-grow: 1;
			min-height: 50%;
			height: 55%;
			margin: 1vh 0 1vh 0;
			width: 90%;
			display: flex;
			flex-direction: column;
		}
	}

	&__btn {
		&-group {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			max-height: 10vh;
			height: 6vw;
			margin-bottom: 2vh;

			button {
				margin: 0 2vw;
			}

			img {
				width: 15vw;
				max-width: 25vh;
				cursor: pointer;
			}
		}
	}

	&__title {
		font-size: 9vh;
		text-transform: uppercase;
		font-weight: bold;
		height: 11%;
		width: 100%;
		text-align: center;
		margin-top: 2%;

		> img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.general {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2vw;
		text-align: center;
		height: 100%;
		flex-grow: 1;
	}

	.event-info {
		line-height: 1.5;

		h2 {
			font-size: 4vh;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 1vh;
		}

		ol {
			font-size: 3vh;
		}

		a {
			color: white;
		}
	}
}

.modal_info {
	.scrollable {
		padding: 2vw 2vw 2vw 4vw;
	}
}
