/* width */
::-webkit-scrollbar {
	width: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(white, 0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
	// background-color: #f2e02f;
	background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/dutyshop/CODMID_h5_duty-shop_scrollbar.png");
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	filter: grayscale(10);
}
