img {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	pointer-events: none;
	&.show {
		pointer-events: initial;
	}
}

button {
	background: transparent;
	border: none;
	padding: 0;
	&:focus {
		outline: none;
	}
}

html,
body,
#root,
#Main,
.content-container {
	min-height: 100%;
	height: 100%;
	width: 100%;
	overflow: hidden;
	-moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

body {
	background: url("../../img/CODMID_h5_duty-shop_BG.jpg") no-repeat top center;
	background-size: cover;
	position: relative;
	z-index: 99;
}

.home {
	width: 100vw;
	height: 100%;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	font-family: "RefrigeratorDeluxe", sans-serif;

	&__body {
		// position: absolute;
		// top: 0;
		width: 100%;
		height: 100%;
	}

	&__content {
		width: 79%;
		margin: 0 auto;
		height: 80%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		// align-items: center;
	}
}

#Main {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
}
