.df-history {
	height: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	&__nav {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		height: 10%;

		button {
			font-size: 0;
			line-height: 0;
			height: 100%;
		}
		&-item {
			height: 100%;
			max-width: 100%;

			// transition: all 0.2s;
			&.active {
				// height: 2.75vw;
			}
		}
	}

	&__content {
		background: rgba(white, 0.15);
		overflow-y: auto;
		flex-grow: 1;
		height: 100%;
		max-height: 40vh;
		padding: 2vw;

		&-title {
			height: 3vw;
			max-height: 8vh;
			max-width: 100%;
			margin-bottom: 1vw;
		}

		table {
			width: 90%;
			margin: 0 auto;
			font-size: 1.4vw;
			//   height: 100%;

			th {
				color: #dbbb26;
				font-weight: bold;
				font-size: 1.6vw;
				text-align: center;

				&:first-child {
					text-align: left;
				}
			}

			td:not(:first-child) {
				text-align: center;
			}
		}
	}
}
